import React from 'react'
import NavbarCSS from '../styles/Navbar.module.css';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useState } from 'react';

const Header = () => {
    const [show, setShow] = useState(false);
    const navbarState = () => {
        // hideFunction(ob);
        setShow(!show);
    }
    return (
        <nav className={NavbarCSS.navbar}>
            <GiHamburgerMenu className={NavbarCSS.navLinkIcon} onClick={() => setShow(!show)}/>
            <div className={show ? `${NavbarCSS.navLinkContainer} ${NavbarCSS.showNavLink}` : `${NavbarCSS.navLinkContainer}`}>
                <NavLink exact to="/" className={NavbarCSS.navLink} activeClassName={NavbarCSS.navLinkActive} onClick={navbarState}>Home</NavLink>
                <NavLink exact to="/about" className={NavbarCSS.navLink} activeClassName={NavbarCSS.navLinkActive} onClick={navbarState}>About</NavLink>
                <NavLink exact to="/projects" className={NavbarCSS.navLink} activeClassName={NavbarCSS.navLinkActive} onClick={navbarState}>Projects</NavLink>
                <NavLink exact to="/contact" className={NavbarCSS.navLink} activeClassName={NavbarCSS.navLinkActive} onClick={navbarState}>Contact</NavLink>
            </div>
        </nav>
    )
}

{/* <nav className={NavbarCSS.navbar}>
<div className={NavbarCSS.flexContainer}>
    <div className={NavbarCSS.icon} onClick={() => setShow(!show)}>&#9776;</div>
</div>
<div className={show ? `${NavbarCSS.navLink} ${NavbarCSS.showLink}` : `${NavbarCSS.navLink}`} >
    <ul>
        <li className={NavbarCSS.item} onClick={() => navbarLogic({
            MarketSummary: false,
            PriceEstimate: true,
            Modelling: true,
            Watchlist: true
        })}>Market Summary</li>
        <li className={NavbarCSS.item} onClick={() => navbarLogic({
            MarketSummary: true,
            PriceEstimate: false,
            Modelling: true,
            Watchlist: true
        })}>Price Estimates</li>
        <li className={NavbarCSS.item} onClick={() => navbarLogic({
            MarketSummary: true,
            PriceEstimate: true,
            Modelling: false,
            Watchlist: true
        })}>Modelling</li>
        <li className={NavbarCSS.item} onClick={() => navbarLogic({
            MarketSummary: true,
            PriceEstimate: true,
            Modelling: true,
            Watchlist: false
        })}>Watchlist</li>
    </ul>
</div>
</nav> */}


export default Header
