
import './App.css';
import { useEffect } from 'react';
import About from './components/About';
import Contact from './components/Contact';
import Projects from './components/Projects';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Home from './components/Home';
import Navbar from './components/Navbar';
import LeftChevron from './components/LeftChevron';
import RightChevron from './components/RightChevron';
import Footer from './components/Footer';
// import { AnimationContext } from './components/Container/AnimationContext';


function App() {
  useEffect(() => {
    document.title = "Lee Maskell | Front End Dev";
  }, []);

  return (
    <div className="App">
      {/* <AnimationContext> */}
      <Navbar />
      <main className="container-fluid">
        <LeftChevron />
        <AnimatePresence>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </AnimatePresence>
        <RightChevron />
      </main>
      <Footer />
      {/* </AnimationContext> */}
    </div>
  );
}

export default App;
