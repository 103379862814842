import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import RightCSS from '../styles/RightChevron.module.css';
import { Link, useLocation } from 'react-router-dom';

const RightChevron = () => {
    const GetLink = () => {
        // check useLocation().path ??? and return page to the 'left' of the current
        let location = useLocation();
        switch (location.pathname) {
            case '/':
                return '/about';
                break;
            case '/about':
                return '/projects';
                break;
            case '/projects':
                return '/contact';
                break;
            case '/contact':
                return '/';
                break;
            default:
                return '/';
                break;
        }
    };
    return (
        <Link to={GetLink()}>
            <FiChevronRight className={RightCSS.chevronRight}/>
        </Link>
    )
}

export default RightChevron
