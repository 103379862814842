import React from 'react';
import FooterCSS from '../styles/Footer.module.css';
import { MdEmail } from 'react-icons/md';
import { SiLinkedin } from 'react-icons/si';
import { FaGithub } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className={FooterCSS.footer}>
            <a href="mailto:lmaskellwebdev@gmail.com"><MdEmail className={FooterCSS.footerIcon}/></a>
            <a href="https://linkedin.com/in/leemaskell"
                        target="blank"><SiLinkedin className={FooterCSS.footerIcon}/></a>
            <a href="https://github.com/LeeMaskell"
                        target="blank"><FaGithub className={FooterCSS.footerIcon}/></a>
        </div>
    )
}

export default Footer
