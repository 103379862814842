import React from 'react'
import AboutCSS from '../styles/About.module.css';
import { motion } from 'framer-motion';

const About = () => {
    const getInitial = () => {
        if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(100%, 0)" };
    }

    const getAnimate = () => {
        console.log(window.innerWidth);
        if (window.innerWidth < 680) return {}
        return { opacity: 1, transform: "translate(0%, 0)" };
    }

    const getExit = () => {
        if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(-50%, 0)" };
    }
    return (
        <motion.div exit={getExit} animate={getAnimate} initial={getInitial}>
            <section className={AboutCSS.about}>
                <div className={AboutCSS.flexDesign}>
                    <div className={AboutCSS.circle}>.</div>
                    <div className={AboutCSS.circle}>.</div>
                    <div className={AboutCSS.circle}>.</div>
                </div>
                <div className={AboutCSS.flexAlign}>
                    <h1 className={AboutCSS.title}>About</h1>
                    <h2 className={AboutCSS.subTitle}>&#123;from: "geologist",<br></br> to: "front end developer&#125;</h2>
                    <p className={AboutCSS.aboutContent}>My name's Lee and I'm an aspiring Front End Web Developer. I have
                        previously worked as a geologist in both the mining and civil/construction industries.
                        I'm now embarking on a new career and have spent the past year studying front end development
                        (8 months full time), with a focus on React/Redux applications and the GatsbyJS front end
                        framework. I'm currently looking for entry level/junior positions to formally start my career.</p>
                </div>
            </section>
        </motion.div>
    )
}

export default About
