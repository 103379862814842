import React from 'react';
import ContactCSS from '../styles/Contact.module.css';
import { motion } from 'framer-motion';

const Contact = () => {
    const getInitial = () => {
        if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(100%, 0)" };
    }

    const getAnimate = () => {
        console.log(window.innerWidth);
      if (window.innerWidth < 680) return {}
        return { opacity: 1, transform: "translate(0%, 0)" };
    }

    const getExit = () => {
      if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(-50%, 0)" };
    }
    return (
        <motion.div exit={getExit} animate={getAnimate} initial={getInitial}>
            <section className={ContactCSS.contact}>
                <div className={ContactCSS.flexDesign}>
                    <div className={ContactCSS.circle}>.</div>
                    <div className={ContactCSS.circle}>.</div>
                    <div className={ContactCSS.circle}>.</div>
                </div>
                <h1 className={ContactCSS.title}>Contact</h1>
                <div className={ContactCSS.flexContainer}>
                    <h2 className={ContactCSS.link}>Email:</h2>
                    <a href="mailto:lmaskellwebdev@gmail.com"><h2 className={ContactCSS.contactLink}>lmaskellwebdev@gmail.com</h2></a>
                    <h2 className={ContactCSS.link}>LinkdIn:</h2>
                    <a href="https://linkedin.com/in/leemaskell"
                        target="blank"><h2 className={ContactCSS.contactLink}> https://linkedin.com/in/leemaskell</h2></a>
                    <h2 className={ContactCSS.link}>GitHub:</h2>    
                    <a href="https://github.com/LeeMaskell"
                        target="blank"><h2 className={ContactCSS.contactLink}>https://github.com/LeeMaskell</h2></a>
                </div>
            </section>
        </motion.div>
    )
}

export default Contact
