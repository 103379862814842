import React from 'react';
import ProjectsCSS from '../styles/Projects.module.css';
import Crypto from '../resources/crypto.png';
import ECommerce from '../resources/ecommerce.png';
import Spotify from '../resources/spotify.png';
import Recipe from '../resources/recipes.png';
import PPD from '../resources/ppd.png';
import PeriodicTable from '../resources/periodic.png';
import WeatherApp from '../resources/weather_app.png';
import BreathingTimer from '../resources/breath_timer.png';
import ToDoList from '../resources/todo.png';
import { motion } from 'framer-motion';
import ProjectItem from './ProjectItem';

const Projects = () => {
    const getInitial = () => {
        if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(100%, 0)" };
    }

    const getAnimate = () => {
        console.log(window.innerWidth);
        if (window.innerWidth < 680) return {}
        return { opacity: 1, transform: "translate(0%, 0)" };
    }

    const getExit = () => {
        if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(-50%, 0)" };
    }
    return (
        <motion.div exit={getExit} animate={getAnimate} initial={getInitial}>
            <section className={ProjectsCSS.projects}>
                <div className={ProjectsCSS.flexDesign}>
                    <div className={ProjectsCSS.circle}>.</div>
                    <div className={ProjectsCSS.circle}>.</div>
                    <div className={ProjectsCSS.circle}>.</div>
                </div>
                <h1 className={ProjectsCSS.title}>PROJECTS</h1>
                <div className={ProjectsCSS.flexContainer}>
                    {/* crypto currency tracker */}
                    <ProjectItem name="Crypto Currency Tracker"
                        url="https://leemaskell.github.io/crypto-currency-tracker/"
                        image={Crypto}
                        alt="crypto currency tracker homepage"
                        description="A crypto currency tracker built in React using the Coin Gecko API"
                        github="https://github.com/LeeMaskell/crypto-currency-tracker" />

                    {/* ecommerce website */}
                    <ProjectItem name="Replica eCommerce Website"
                        url="https://leemaskell.github.io/ecommerce_replica_website/"
                        image={ECommerce}
                        alt="eCommerce replica homepage"
                        description="A replica eCommerce website using the React/Redux libraries (scaled down functionality)"
                        github="https://github.com/LeeMaskell/ecommerce_replica_website" />

                    {/* six degrees of spotify */}
                    <ProjectItem name="Six Degrees of Spotify"
                        url="https://six-degrees-of-spotify.leemaskell.com"
                        image={Spotify}
                        alt="Six Degrees of Spotify homepage"
                        description="A React game application using the Spotify API"
                        github="https://github.com/LeeMaskell/six_degrees_of_spotify" />
                    
                    {/* Lyla's Recipes */}
                    <ProjectItem name="Lyla's Recipes"
                        url="https://leemaskell.github.io/Lylas-recipes/"
                        image={Recipe}
                        alt="Lyla's Recipes homepage"
                        description="A GatsbyJS recipe website using the Contentful API headless CMS"
                        github="https://github.com/LeeMaskell/Lylas-recipes" />

                    {/* periodic table */}
                    <ProjectItem name="Periodic Table of the Elements"
                        url="https://leemaskell.github.io/periodic-table/"
                        image={PeriodicTable}
                        alt="Periodic table homepage"
                        description="A static website built with GatsbyJS"
                        github="https://github.com/LeeMaskell/periodic-table" />

                    {/* band website */}
                    <ProjectItem name="Band Website"
                        url="https://poorpremenstrualdarling.com/"
                        image={PPD}
                        alt="Band website homepage"
                        description="A static band website built with GatsbyJS"
                        github="https://github.com/LeeMaskell/poor_premenstrual_darling" />

                    {/* weather app */}
                    <ProjectItem name="Weather App"
                        url="https://leemaskell.github.io/weather_app/"
                        image={WeatherApp}
                        alt="Weather app homepage"
                        description="A React weather app using the OpenWeatherMap API"
                        github="https://github.com/LeeMaskell/weather_app" />

                    {/* breathing timer */}
                    <ProjectItem name="Breathing Timer"
                        url="https://leemaskell.github.io/breathing_timer/"
                        image={BreathingTimer}
                        alt="Breathing Timer homepage"
                        description="An interactive breathing timer built with vanilla JavaScript and CSS animations"
                        github="https://github.com/LeeMaskell/breathing_timer" />

                    {/* to do list */}
                    <ProjectItem name="To Do List"
                        url="https://leemaskell.github.io/ToDo_List/"
                        image={ToDoList}
                        alt="To do list homepage"
                        description="A vanilla JavaScript application using local Storage"
                        github="https://github.com/LeeMaskell/ToDo_List" />

                </div>
            </section>
        </motion.div>
    )
}

export default Projects
