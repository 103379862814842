import React from 'react';
import ProjectsCSS from '../styles/Projects.module.css';
import { BsGithub } from 'react-icons/bs';

const ProjectItem = (props) => {
    return (
        <div className={ProjectsCSS.projectItem}>
            <h2 className={ProjectsCSS.projectTitle}>{props.name}</h2>
            <a href={props.url} target="_blank"><div className={ProjectsCSS.imageContainer}>
                <img src={props.image} alt={props.alt} className={ProjectsCSS.projectImage} />
            </div></a>
            <div className={ProjectsCSS.projectDetails}>
                <p className={ProjectsCSS.text}>{props.description}</p>
                <a href={props.github} target="_blank" className={ProjectsCSS.githubLink}><BsGithub /> View Code</a>
            </div>
        </div>
    )
}

export default ProjectItem
