import React from 'react'
import HomeCSS from '../styles/Home.module.css';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
// import { useContext } from 'react';
// import Context from './Container/AnimationContext';

const Home = ({ props }) => {
    const history = useHistory();
    const handleButtonClick = () => {
        history.push('/Contact')
    }
    const getInitial = () => {
        if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(100%, 0)" };
    }

    const getAnimate = () => {
        console.log(window.innerWidth);
        if (window.innerWidth < 680) return {}
        return { opacity: 1, transform: "translate(0%, 0)" };
    }

    const getExit = () => {
        if (window.innerWidth < 680) return {}
        return { opacity: 0, transform: "translate(-50%, 0)" };
    }
    // const animationContext = useContext(Context); use when context is linked and remove the above functions

    return (
        <motion.div exit={getExit} animate={getAnimate} initial={getInitial}>
            <section className={HomeCSS.home}>
                <div className={HomeCSS.flexDesign}>
                    <div className={HomeCSS.circle}>.</div>
                    <div className={HomeCSS.circle}>.</div>
                    <div className={HomeCSS.circle}>.</div>
                </div>
                <h3 className={HomeCSS.name}>LEE MASKELL</h3>
                <h2 className={HomeCSS.title}>&#123;FRONT END WEB DEVELOPER&#125;</h2>
                <h4 className={HomeCSS.languages}>HTML . CSS . JavaScript . React . GatsbyJS</h4>
                <button onClick={handleButtonClick} className={HomeCSS.btn}>Contact</button>
            </section>
        </motion.div>
    )
}

export default Home
